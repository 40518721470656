import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../button/Button";
import Star from "../Star/Star";
import courseData from "../data/course.json";
import Video from "../video/Video";
import ArrowIcon from "../svg/arrow.svg";
import SubmitIcon from "../svg/submit.svg";

export default function RecordVideo() {

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        course_id: "",
        rating: 0,
        file: null,
        review: " ",
      });


      const [options, setOptions] = useState([]);
      const [isLoading, setIsLoading] = useState(false);

      useEffect(() => {
        const formattedOptions = Object.keys(courseData.data)
          .sort((a, b) => parseInt(a) - parseInt(b)) // Sort keys numerically
          .map((key) => ({
            value: key,
            label: courseData.data[key],
          }));
        setOptions(formattedOptions);
      }, []);

      const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      }, []);

      const handleFileChange = useCallback((file) => {
        setFormData((prevData) => ({ ...prevData, file }));
      }, []);
    
      const handleRatingChange = useCallback((rating) => {
        setFormData((prevData) => ({ ...prevData, rating: parseInt(rating, 10) }));
      }, []);

      const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        setIsLoading(true); // Start loading
    
        // Create a plain object with the form data
        const data = {
          name: formData.name,
          email: formData.email,
          course_id: formData.course_id,
          rating: formData.rating, // This should be an integer
          file: formData.file,
        };
    
        // Log the data and their types to the console
        // for (let key in data) {
        //   console.log(`${key}: ${data[key]} (${typeof data[key]})`);
        // }
    
        const formDataToSend = new FormData();
        formDataToSend.append("name", data.name);
        formDataToSend.append("email", data.email);
        formDataToSend.append("course_id", data.course_id);
        formDataToSend.append("rating", data.rating.toString()); // Convert to string for FormData
        formDataToSend.append("video", data.file);
    
        const customErrorMessages = {
          video: "Please record Video",
          email: "Email already exists",
          rating: "Please give rating",
        };
    
        try {
          const response = await fetch(
            "https://api-reviews.siddhiyoga.com/video-review/",
            {
              method: "POST",
              headers: {
                "X-Custom-Code": "123456", // Add custom header here
              },
              body: formDataToSend,
            }
          );
    
          if (response.ok) {
            // Handle successful submission
            alert("Review submitted successfully");
          } else {
            // Parse and handle error response
            const errorData = await response.json();
            const errorMessages = Object.entries(errorData)
              .map(([key, value]) => customErrorMessages[key] || value)
              .join("\n");
            alert(`Failed to submit review:\n${errorMessages}`);
          }
        } catch (error) {
          // Handle network or other errors
          alert(`Error submitting review: ${error.message}`);
        } finally {
          setIsLoading(false); // Stop loading
        }
      };


  return (
    <>
      <div className="outer-container">
        {isLoading && <div className="dull-background"></div>}
        {isLoading && <div className="loader"></div>} {/* Loader */}
        <div className="container">

        <div className="stateNavigate">
          <Button
            onClick={() => navigate("/")}
            icon={ArrowIcon}
            classN="backBtn"
          />
        </div>

        <div className="logo">
           
            <img
              src="https://www.siddhiyoga.com/wp-content/uploads/2023/03/logo.svg"
              alt="Siddhi Yoga Logo"
            />
          </div>

          <div className="heading">
            <h2>Record a Video</h2>
          </div>

          <h3 className="secondHeading">
            Show Us Your Beautiful Smile & Liveliness
          </h3>

          <p className="para">
            Your story matters and the world needs more of your light!
          </p>


          <form onSubmit={handleSubmit}>
            <div className="inputs">
              <input
                type="text"
                name="name"
                placeholder="Enter your name"
                onChange={handleInputChange}
                required // Ensure the field is filled
              />
              <br />
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                onChange={handleInputChange}
                required // Ensure the field is filled
              />
            </div>

            <select
              className="dropDown"
              name="course_id"
              value={formData.course_id}
              onChange={handleInputChange}
              required // Ensure the field is filled
            >
              <option value="" disabled>
                Choose your course
              </option>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>

            <h6 className="showerStar">Shower Us With Some Stars</h6>
            <Star
              rating={formData.rating}
              setRating={handleRatingChange}
              required
            />
            <Video onFileSelect={handleFileChange} required />

            {/* <label>Upload Your Video</label>
<FileUpload onFileSelect={handleFileChange} /> */}

            <div className="submitbtn ">
              <button>
                <span className="buttonImg">
                  <img src={SubmitIcon} alt="Upload" />
                </span>
                <span className="buttonText">Submit</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
