import React, { useRef, useState } from 'react';
import UploadIcon from '../svg/upload.svg';


export default function FileUpload({ onFileSelect }) {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    onFileSelect(selectedFile);
  };

  const fileBtn = {
    backgroundColor: "white",
    color: "#45B29D",
    border: "none",
    borderBottom: "2px solid #45B29D",
    fontWeight: "500",
    marginBottom: "20px",
  };


  return (
    <div>
      <button 
      type="button" 
      className='uploadImg' 
      onClick={handleClick} 
      style={fileBtn}>
        <span  >
        <img src={UploadIcon} alt="Upload" />

        </span>
        <span className='buttonText'> Upload Image</span>
      </button>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleChange}
      />
      {file && <p>Selected File: {file.name}</p>}
    </div>
  );
}
