import { Link, useNavigate } from "react-router-dom";
import Button from "./button/Button";
import ArrowIcon from "./svg/arrow.svg";
import RecordV from "./svg/recordV.svg";
import UploadIcon from "./svg/upload.svg";

export default function TextReview() {
  const navigate = useNavigate();

  const handleUploadClick = () => {
    window.open(
      "https://www.dropbox.com/request/dGrlSXxf72M9esdaf95h",
      "_blank"
    );
  };

  return (
    <>
      <div className="outer-container">
        <div className="container">
          <div className="stateNavigate">
            <Button
              onClick={() => navigate("/")}
              icon={ArrowIcon}
              classN="backBtn"
            />
          </div>

          <div className="logo">
            <img
              src="https://www.siddhiyoga.com/wp-content/uploads/2023/03/logo.svg"
              alt="Siddhi Yoga Logo"
            />
          </div>
          <div className="heading">
            <h2>Sharing Video Testimonial?</h2>
          </div>
          <p className="secondHeading">
            Show Us Your Beautiful Smile & Liveliness
          </p>
          <p className="para">
            Your story matters and the world needs more of your light!
          </p>

          <h5>Here’s what you can tell in your video testimonial?</h5>
          <ol className="custom-list">
            <li>Why Online YTT?</li>
            <li>Why did you choose Siddhi Yoga?</li>
            <li>
              Your unique experiences with the Siddhi Yoga team and instructors?
            </li>
            <li>How Siddhi Yoga changed you, positively?</li>
          </ol>

          <div className="videoOptions">
            <Button
              onClick={handleUploadClick}
              icon={UploadIcon}
              text="Upload File with Dropbox"
              classN="uploadBtn"
            />

            <label>or</label>

            <Link to="/record-video">
              <button className="recordBtn">
                <img src={RecordV} alt="record" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
