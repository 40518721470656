import React from 'react';
import './Star.css';
import { useState } from 'react';

export default function Star({ rating, setRating }) {
  const [initialHoverCount, setInitialHoverCount] = useState(0);

  const handleClick = (index) => {
    setRating(index + 1);
    // console.log('Rating Value:', index + 1); // Console the rating value whenever it changes
  };

  // if (rating === 0){
  //   return null;
  // }

  return (
    <div>
      <div className="stars">
        {[...Array(5)].map((_, index) => {
          return (
            <span
              key={index}
              className={`${index + 1 <= rating ? 'selected' : ''} ${index + 1 <= initialHoverCount ? 'selected' : ''}`}
              onMouseOver={() => {
                setInitialHoverCount(index + 1);
              }}
              onMouseOut={() => {
                setInitialHoverCount(0);
              }}
              onClick={() => {
                handleClick(index);
              }}
            >
              &#9733;
            </span>
          );
        })}
      </div>
    </div>
  );
}
