import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Home from './components/Home/Home';
import TextReview from './components/textreview/TextReview'; 
import VideoReview from './components/VideoReview';
import RecordVideo from './components/recordVideo/RecordVideo';


import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home/>,
    
  },
  {
    path: '/text-review',
    element: <TextReview/>
  },
  {
    path: '/video-review',
    element: <VideoReview/>
  },
  {
    path: '/record-video',
    element: <RecordVideo/>
  }
  
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
    
  </React.StrictMode>
);


reportWebVitals();
