import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import videoTesti from "../svg/videoTesti.svg";
import textTesti from "../svg/textTesti.svg";

export default function Home() {
  return (
    <div className="outer-container">
      <div className="container">
        <div className="logo">
          <img
            src="https://www.siddhiyoga.com/wp-content/uploads/2023/03/logo.svg"
            alt="png"
          />
        </div>

        <h1 className="headingMain">Spread Positivity with</h1>
        <h1 className="headingMain">Your Wonderful Reviews </h1>
        <p className="intro">
          Share your amazing experiences with Siddhi Yoga, and we’ll share you
          Karma points for rolling out positive energy.
        </p>

        <div className="homeBtn">
          <Link to="/video-review">
            <button className="homeInnerBtn1">
              <img src={videoTesti} alt="video" />
            </button>
          </Link>
          <label>or </label>
          <Link to="/text-review">
            <button className="homeInnerBtn2">
              <img src={textTesti} alt="text" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
