import React, { useEffect, useRef, useState } from 'react';
import './Video.css';
import StartRec from '../svg/startRec.svg';
import StopRec from '../svg/stopRec.svg';

export default function Video({ onFileSelect, inputName }) {
  const [isRecording, setIsRecording] = useState(false);  // State to track recording status
  const [cameraError, setCameraError] = useState('');
  const [videoSrc, setVideoSrc] = useState('');
  const [videoBlob, setVideoBlob] = useState(null);
  const [isRecorded, setIsRecorded] = useState(false);
  // const [button, setButton] = useState("Start Recording");

  const videoLiveRef = useRef(null);
  const videoRecordedRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recordedChunks = useRef([]);

  useEffect(() => {
    async function main() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });

        if (videoLiveRef.current) {
          videoLiveRef.current.srcObject = stream;
        }

        const options = getSupportedMimeType();

        if (!options) {
          setCameraError('No supported MIME type found for recording.');
          return;
        }

        const mediaRecorder = new MediaRecorder(stream, options);
        mediaRecorderRef.current = mediaRecorder;

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            recordedChunks.current.push(event.data);
          }
        };

        mediaRecorder.onstop = () => {
          const blob = new Blob(recordedChunks.current, { type: options.mimeType });
          recordedChunks.current = [];
          const videoURL = URL.createObjectURL(blob);
          setVideoSrc(videoURL);
          setVideoBlob(blob);

          // Create a new File object with the desired name and extension
          const videoFile = new File([blob], `video.${options.mimeType.split('/')[1] || 'webm'}`, {
            type: options.mimeType,
          });

          onFileSelect(videoFile);
          setIsRecorded(true);
          setIsRecording(false);  // Reset recording status
          // setButton("Start Recording");  
        };

      } catch (err) {
        console.error('Error accessing media devices.', err);
        setCameraError("We can't access your camera! To earn some good karma points: click the lock icon in your browser's address bar, grant camera and microphone access, then reload this page. And remember, your camera needs to be connected to capture your yogic glow!");
      }
    }

    main();
  }, [onFileSelect, inputName]);

  useEffect(() => {
    if (isRecorded && videoRecordedRef.current) {
      videoRecordedRef.current.src = videoSrc;
    }
  }, [isRecorded, videoSrc]);

  const getSupportedMimeType = () => {
    const mimeTypes = [
      'video/webm;codecs=vp8,opus',
      'video/webm;codecs=vp9,opus',
      'video/webm',
      'video/mp4',
    ];
    
    for (const mimeType of mimeTypes) {
      if (MediaRecorder.isTypeSupported(mimeType)) {
        return { mimeType };
      }
    }

    return null;
  };

  const handleButtonClick = () => {
    const mediaRecorder = mediaRecorderRef.current;
    if (!isRecording) {
      try {
        mediaRecorder.start();
        setIsRecording(true);
        setIsRecorded(false);
      } catch (err) {
        console.error('Error starting media recorder:', err);
        setCameraError('Error starting media recorder. Please try again.');
      }
    } else {
      mediaRecorder.stop();
      setIsRecording(false);
    }
  };
  
 

  return (
    <>
      
      <div className="video-container">
        {cameraError ? (
          <p className='error'>
            {cameraError}
          </p>
        ) : (
          <>
            <video autoPlay muted playsInline ref={videoLiveRef} id="videoLive"></video>
            <div className={`red-dot ${isRecording ? 'active' : ''}`}></div>
          </>
        )}
        
      </div>

      <div>
        <button 
        className="recordVideoBtn" 
        type='button' 
        id='StartStop' 
        // style={buttonStyle} 
        disabled={cameraError} 
        onClick={handleButtonClick}
        >
          <span>
          {isRecording ? (
              <img src={StopRec} alt="Stop Recording" />
            ) : (
              <img src={StartRec} alt="Start Recording" />
            )}
          </span>
          {/* {button} */}
          </button>
      </div>

      <div id='messageContainerStyle'>
        {/* {isRecording && (
          <div>
            <p>Recording Started</p>
          </div>
        )} */}
        {isRecorded && (
          <div>
            <p>Video recorded , Please fill the above form</p>
          </div>
        )}
      </div>
    </>
  );
}